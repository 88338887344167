// eslint-disable-next-line
export default {
    basename: '/',
    // api_base: 'https://api.staging.loco.hkrnd.com/api',
    api_base: 'https://api.oilcms.hkrnd.com/api',
    home_menu_item_id: 'list_exp_card',
    home_url: '/list/exp_card',
    theme: 'light',
    rtlLayout: false,
    i18n: 'en'
};
